



















































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    }),
    ...mapState("assets", {
      oss: "oss"
    }),
    titleList() {
      return [
        this.$t("cybermat.shortTitle01"),
        this.$t("cybermat.shortTitle02"),
        this.$t("cybermat.shortTitle03"),
        this.$t("cybermat.shortTitle04"),
        this.$t("cybermat.shortTitle05"),
        this.$t("cybermat.shortTitle06"),
        this.$t("cybermat.shortTitle07"),
        this.$t("joinUs"),
        "FOOTER"
      ];
    }
  }
});
